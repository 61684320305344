<template>
  <div
    :class="[
      'menu-section mb-4',
      { 'px-3': !isSubsection },
      { subsection: isSubsection },
      entity.section_type ? `type--${entity.section_type}` : '',
    ]"
    @mouseover.stop="isHovering = true"
    @mouseleave.stop="isHovering = false"
  >
    <slot>
      <div class="menu-section--top mb-3">
        <BaseEditable
          v-if="entity.title"
          ref="title"
          class="menu-section--title blanch--font"
          tag="span"
          :content="entity.title"
          :mods="myMods.title"
          @update="updateSectionAction('title', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
        <div v-if="entity.section_subtitle_note" class="menu-section--subtitle">
          <template v-if="entity.section_subtitle_note[0] === '{'">
            <component
              :is="getTagForElem(elem.type)"
              v-for="elem of JSON.parse(entity.section_subtitle_note).content"
              :key="JSON.stringify(elem.text)"
              >{{ elem.text }}</component
            >
          </template>
          <BaseEditable
            v-else
            tag="span"
            :content="entity.section_subtitle_note"
            :mods="myMods.section_subtitle_note"
            @update="updateSectionAction('section_subtitle_note', $event)"
            @dirtyUpdate="adjustDirtScore"
          />
        </div>
      </div>
      <div class="menu-section--items">
        <BaseButton
          v-if="!items.length && isShowingEditUI"
          md-icon="add"
          text-color="green"
          color="green"
          small
          round
          outline
          @click="stageNewItem({ sectionId: entity.id })"
          >Add an Item</BaseButton
        >
        <Draggable
          v-else
          v-model="items"
          handle=".drag-handle"
          :group="{ name: 'menu-items' }"
          draggable=".menu-item"
          @change="dragEventAction"
        >
          <template v-for="(item, i) of items">
            <MenuItem
              :key="item.id"
              :ref="`item-${i}`"
              class="asap--font"
              :entity="{
                ...item,
                ...{ isLast: i === items.length - 1 },
                // eslint-disable-next-line vue/camelcase
                updated_at: entity.updated_at,
              }"
              :my-mods="itemMods[item.id] ? itemMods[item.id] : {}"
              :is-condensed="entity.section_type === 'condensed'"
              @addItemBefore="addItemAction({ sectionId: entity.id, index: i })"
              @addItemAfter="
                addItemAction({ sectionId: entity.id, index: i + 1 })
              "
              @deleteItem="
                removeItem({ sectionId: entity.id, itemId: item.id })
              "
              @updateItem="updateItemAction(entity.id, $event)"
            />
          </template>
        </Draggable>
      </div>
      <div v-if="subsections.length" class="menu-section--sub-menus">
        <WithMenuSection
          v-for="subsection of subsections"
          :id="subsection.id"
          v-slot="{
            isSectionLoading: isSubsectionLoading,
            section: theSubsection,
            itemMods: subsectionItemMods,
            myMods: subsectionMods,
          }"
          :key="subsection.id"
        >
          <component
            :is="subsection.is_callout ? 'MenuSectionCallout' : 'MenuSection'"
            :item-mods="subsectionItemMods"
            :my-mods="subsectionMods"
            :is-subsection="true"
            :entity="theSubsection"
            @updateTitle="
              updateSectionAction('title', $event, theSubsection.id)
            "
            @updateSubtitle="
              updateSection('section_subtitle_note', $event, theSubsection.id)
            "
          />
        </WithMenuSection>
      </div>
      <!-- <EntityModButtons
      v-if="isHovering && isShowingEditUI"
      class="section-buttons--wrap"
      bottom
      add-btn-color="blue"
      entity-name="Section"
      show-delete
      :hide-buttons="isSelected && !isHovering"
      @add="$emit('addSectionAfter')"
      @delete="$emit('deleteSection')"
    /> -->
    </slot>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import MenuSectionMixin from '@mixins/menu-section'

import MenuItem from '@menu/MenuItem'
import MenuSectionCallout from '@menu/MenuSectionCallout'
import EntityModButtons from '@menu/EntityModButtons'

export default {
  name: 'MenuSection',
  components: {
    MenuItem,
    MenuSectionCallout,
    Draggable,
    EntityModButtons,
  },
  mixins: [MenuSectionMixin],
}
</script>

<style lang="scss">
@import '@design';

.menu-section {
  position: relative;
  &.subsection {
    margin-top: 50px;
  }
}

.menu-section--title {
  margin-bottom: 0.1em;
  font-size: 60px;
  line-height: 0.7;
  // @media (max-width:600px) {
  //   position:sticky;
  //   top:0;
  //   background-color:white;
  // }
}

.menu-section--subtitle {
  font-size: 1.15rem;
  font-weight: 500;
  span {
  }
  i {
    // font-weight:300;
    font-size: 0.8rem;
  }
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}
</style>
