var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'menu-section mb-4',
    { 'px-3': !_vm.isSubsection },
    { subsection: _vm.isSubsection },
    _vm.entity.section_type ? ("type--" + (_vm.entity.section_type)) : '' ],on:{"mouseover":function($event){$event.stopPropagation();_vm.isHovering = true},"mouseleave":function($event){$event.stopPropagation();_vm.isHovering = false}}},[_vm._t("default",[_c('div',{staticClass:"menu-section--top mb-3"},[(_vm.entity.title)?_c('BaseEditable',{ref:"title",staticClass:"menu-section--title blanch--font",attrs:{"tag":"span","content":_vm.entity.title,"mods":_vm.myMods.title},on:{"update":function($event){return _vm.updateSectionAction('title', $event)},"dirtyUpdate":_vm.adjustDirtScore}}):_vm._e(),(_vm.entity.section_subtitle_note)?_c('div',{staticClass:"menu-section--subtitle"},[(_vm.entity.section_subtitle_note[0] === '{')?_vm._l((JSON.parse(_vm.entity.section_subtitle_note).content),function(elem){return _c(_vm.getTagForElem(elem.type),{key:JSON.stringify(elem.text),tag:"component"},[_vm._v(_vm._s(elem.text))])}):_c('BaseEditable',{attrs:{"tag":"span","content":_vm.entity.section_subtitle_note,"mods":_vm.myMods.section_subtitle_note},on:{"update":function($event){return _vm.updateSectionAction('section_subtitle_note', $event)},"dirtyUpdate":_vm.adjustDirtScore}})],2):_vm._e()],1),_c('div',{staticClass:"menu-section--items"},[(!_vm.items.length && _vm.isShowingEditUI)?_c('BaseButton',{attrs:{"md-icon":"add","text-color":"green","color":"green","small":"","round":"","outline":""},on:{"click":function($event){return _vm.stageNewItem({ sectionId: _vm.entity.id })}}},[_vm._v("Add an Item")]):_c('Draggable',{attrs:{"handle":".drag-handle","group":{ name: 'menu-items' },"draggable":".menu-item"},on:{"change":_vm.dragEventAction},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},[_vm._l((_vm.items),function(item,i){return [_c('MenuItem',{key:item.id,ref:("item-" + i),refInFor:true,staticClass:"asap--font",attrs:{"entity":Object.assign({}, item,
              { isLast: i === _vm.items.length - 1 },
              // eslint-disable-next-line vue/camelcase
              {updated_at: _vm.entity.updated_at}),"my-mods":_vm.itemMods[item.id] ? _vm.itemMods[item.id] : {},"is-condensed":_vm.entity.section_type === 'condensed'},on:{"addItemBefore":function($event){return _vm.addItemAction({ sectionId: _vm.entity.id, index: i })},"addItemAfter":function($event){return _vm.addItemAction({ sectionId: _vm.entity.id, index: i + 1 })},"deleteItem":function($event){return _vm.removeItem({ sectionId: _vm.entity.id, itemId: item.id })},"updateItem":function($event){return _vm.updateItemAction(_vm.entity.id, $event)}}})]})],2)],1),(_vm.subsections.length)?_c('div',{staticClass:"menu-section--sub-menus"},_vm._l((_vm.subsections),function(subsection){return _c('WithMenuSection',{key:subsection.id,attrs:{"id":subsection.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isSubsectionLoading = ref.isSectionLoading;
        var theSubsection = ref.section;
        var subsectionItemMods = ref.itemMods;
        var subsectionMods = ref.myMods;
return [_c(subsection.is_callout ? 'MenuSectionCallout' : 'MenuSection',{tag:"component",attrs:{"item-mods":subsectionItemMods,"my-mods":subsectionMods,"is-subsection":true,"entity":theSubsection},on:{"updateTitle":function($event){return _vm.updateSectionAction('title', $event, theSubsection.id)},"updateSubtitle":function($event){return _vm.updateSection('section_subtitle_note', $event, theSubsection.id)}}})]}}],null,true)})}),1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }